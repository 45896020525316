import About from "./about";
import Contact from "./contact";
import Home from "./home";
import Projects from "./projects";
import Trajectoty from "./trajectory";

const All = () => {

    return(
      <div>
        <Home/>
        <About/>
        <Trajectoty/>
        <Projects/>
        <Contact/>
      </div>
    );
};

export default All;