import React from 'react';
import './timeline.css';

const TimelineEvent = () => (
  <div className="timeline-event"></div>
);

const TimelineItem = ({ title, description, date, photo }) => (
  <div className="timeline-item">
    <TimelineEvent />
    <div className="timeline-item-content">
      <span className="timeline-item-date">{date}</span>
      <h3 className="timeline-item-title">{title}</h3>
      <p className="timeline-item-description">{description}</p>
      {/* <img src={photo} style={{width: 500}} alt={photo}></img> */}
    </div>
  </div>
);

const Timeline = ({ data }) => (
  <div className="timeline">
    {data.map((item, index) => (
      <TimelineItem key={index} {...item} />
    ))}
  </div>
);

export default Timeline;