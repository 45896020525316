import { useTranslation } from 'react-i18next';
import Timeline from "./components/timeline";
import estadisticas from "../images/estadisticas.svg";

const Trajectoty = () => {

  const [t] = useTranslation("global");
  
  const data = [
    { title: t("trajectory.job1.title"), description: t("trajectory.job1.description"), date: t("trajectory.job1.date"), photo: estadisticas },
    { title: t("trajectory.job2.title"), description: t("trajectory.job2.description"), date: t("trajectory.job2.date") }
  ];

  return(
    <div id="trajectory" style={{marginTop: 50}}>
      <section className='sectionAbout'>
        <label className='title'>{t("trajectory.title")}</label>
      </section>
    <Timeline data={data}/>
    </div>
  );
};

export default Trajectoty;