/* eslint-disable react-hooks/exhaustive-deps */

const NotFoundPage = () => {

    return(
      <div>
        404
      </div>
    );
};

export default NotFoundPage;