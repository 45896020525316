import { useTranslation } from 'react-i18next';
import "./about.css";

const About = () => {

  const [t] = useTranslation("global");

  return(
    <div id="about" style={{marginTop: 50}}>
      <section className='sectionAbout'>
        <label className='title'>{t("about.title")}</label>
        <label style={{textAlign: "left", marginTop: 32}} className='parrafo'>{t("about.parrafo1")}</label>
        <label style={{textAlign: "left", marginBottom: 32}} className='parrafo'>{t("about.parrafo2")}</label>
        <label style={{textAlign: "left"}} className='parrafo'>{t("about.parrafo3")}</label>
        <label style={{textAlign: "left"}} className='parrafo'>{t("about.parrafo4")}</label>
        <label style={{textAlign: "left", marginBottom: 32}} className='parrafo'>{t("about.parrafo5")}</label>
        <label style={{textAlign: "left"}} className='parrafo'>{t("about.parrafo6")}</label>
        <label style={{textAlign: "left"}} className='parrafo'>{t("about.parrafo7")}</label>
      </section>
      <div className='divHabilidades' style={{display: "flex", justifyContent: "center"}}>
        <section className='habilidades' style={{marginTop: "4rem"}}>
          <label className='titleHabilidades' style={{display: "flex"}}>{t("about.habilidadesTitle")}</label>
          <div className='sectionHabilidades'>
            <div class="box">Javascript</div>
            <div class="box">HTML</div>
            <div class="box">CSS</div>
            <div class="box">React</div>
            <div class="box">Bootstrap</div>
            <div class="box">Node JS</div>
            <div class="box">Docker</div>
            <div class="box">Redux</div>
            <div class="box">Express</div>
            <div class="box">Mongo DB</div>
            <div class="box">MySQL</div>
            <div class="box">WordPress</div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;