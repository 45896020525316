/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import memo from "../images/memo.png";

const Projects = () => {

  const [t] = useTranslation("global");

  return(
    <div id="projects" style={{marginTop: 50}}>
      <section className='sectionAbout'>
        <label className='title'>{t("projects.title")}</label>
        <div className='divProjects'>
          <a href="https://memo-bx6js095.b4a.run/" className='aMemo' style={{display: "flex"}} target='blank'>
            <div className='memo'>
              <label style={{color: "white", textDecoration: "none", marginBottom: 20, display: "flex", justifyContent: "center"}}>{t("projects.projects1.title")}</label>
              <img style={{width: "100%"}} className='fotoMemo' src={memo} alt='memory game'></img>
            </div>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Projects;