import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import {I18nextProvider} from "react-i18next";
import global_es from "./languages/es/global.json"
import global_en from "./languages/en/global.json"
import i18next from "i18next";
import '@fortawesome/fontawesome-free/css/all.css';
import { Analytics } from "@vercel/analytics/react"

i18next.init({
  interpolation: { escapeValue: false},
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <App/>
      <Analytics/>
    </Provider>
  </I18nextProvider>
);