/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import avatar from "../images/dibujo.svg";
import tecnologia from "../images/tecnologia.svg";
import email from "../images/email.svg";
import instagram from "../images/instagram.svg";
import linkedin from "../images/linkedin.svg";

const Home = () => {

  const [t] = useTranslation("global");

    return(
      <div id='home' style={{paddingBottom: 50, borderBottom: "2px solid white"}}>
        <div className='fotoTitle' style={{display: "flex"}}>
          <div>
            <section className='sectionHome'>
              <div style={{marginBottom: 16}}>
                <span className='title'>{t("home.title")}</span>
              </div>
              <div>
                <span className='description1'>{t("home.description1")}</span>
                <span className='description2'>{t("home.description2")}</span>
              </div>
            </section>
            <div className='divRedesSociales' style={{display: "flex"}}>
              <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/cristian-aldo-novelli-287ba0201/'><img src={linkedin} className='redesSociales' alt='linkedin'></img></a>
              <a target="_blank" rel="noreferrer" href='https://www.instagram.com/_cristian__21/'><img src={instagram} className='redesSociales' alt='instagram'></img></a>
              <a target="_blank" rel="noreferrer" href="mailto:novellicristianaldo@gmail.com"><img src={email} className='redesSociales' alt='email'></img></a>
            </div>
          </div>
          <img src={avatar} className='avatar' alt='avatar'></img>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <img src={tecnologia} className='tecnologia' alt='tecnologia'></img>
        </div>
      </div>
    );
};

export default Home;