import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import eng from "./images/languages/eng.svg";
import es from "./images/languages/es.svg";
import NotFoundPage from "./components/notFoundPage.jsx"; // Importa tu componente NotFoundPage
import All from "./components/all.jsx";

const languageOptions = [
  { value: 'es', label: 'ES', flag: es }, // España
  { value: 'en', label: 'EN', flag: eng } // Reino Unido
];

const AppStructure = () => {
  const [homeClicked, setHomeClicked] = useState(true);
  const [aboutClicked, setAboutClicked] = useState(false);
  const [trajectoryClicked, setTrajectoryClicked] = useState(false);
  const [projectsClicked, setProjectsClicked] = useState(false);
  const [contactClicked, setContactClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(languageOptions[0].value);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    changeLanguage(value);
  };

  const [t, i18n] = useTranslation("global");

  const clearMenu = () => {
    setHomeClicked(false);
    setAboutClicked(false);
    setProjectsClicked(false);
    setTrajectoryClicked(false);
    setContactClicked(false);
  };

  const onClickMenu = (funcion) => {
    clearMenu();
    funcion(true);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const menu = document.querySelector('.menuTotal');
      const checkbox = document.getElementById('check');

      if (!menu.contains(event.target) && !checkbox.contains(event.target)) {
        checkbox.checked = false;
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Router>
      <div className="bodyTotal">
        <input type="checkbox" className="menuBar" name="" id="check" />
        <div className="menuTotal">
          <label className="labelMenu" htmlFor="check">
              <span className="fas fa-times" id="times"></span>
              <span className="fas fa-bars" id="bars"></span>
          </label>
          <div className="headMenu" style={{minWidth: 220, padding: 8, display: "flex", justifyContent: "start"}}>
              <label style={{ color: "#22C8F7" }}>{"<Cristian Aldo Novelli/>"}</label>
          </div>
          <nav className="menu">
              <div className="listMenu">
                  <button onClick={() => {onClickMenu(setHomeClicked); handleScrollToSection('home')}} className={homeClicked ? "clicked" : "normal"}>{t("menu.home")}</button>
              </div>
              <div className="listMenu">
                  <button onClick={() => {onClickMenu(setAboutClicked); handleScrollToSection('about');}} className={aboutClicked ? "clicked" : "normal"}>{t("menu.about")}</button>
              </div>
              <div className="listMenu">
                  <button onClick={() => {onClickMenu(setTrajectoryClicked); handleScrollToSection('trajectory')}} className={trajectoryClicked ? "clicked" : "normal"}>{t("menu.trajectory")}</button>
              </div>
              <div className="listMenu">
                  <button onClick={() => {onClickMenu(setProjectsClicked); handleScrollToSection('projects');}} className={projectsClicked ? "clicked" : "normal"}>{t("menu.projects")}</button>
              </div>
              <div className="listMenu">
                  <button onClick={() => {onClickMenu(setContactClicked); handleScrollToSection('contact');}} className={contactClicked ? "clicked" : "normal"}>{t("menu.contact")}</button>
              </div>
              <div className="dark-select">
                  <div className="dark-select__selected" onClick={toggleOpen}>
                      <img src={languageOptions.find(option => option.value === selectedValue).flag} alt={selectedValue} />
                      <span>{selectedValue.toUpperCase()}</span>
                  </div>
                  {isOpen && (
                      <ul className="dark-select__options">
                          {languageOptions.map((option) => (
                              <li
                                  key={option.value}
                                  className="dark-select__option"
                                  onClick={() => handleOptionClick(option.value)}
                              >
                                  <img src={option.flag} alt={option.label} />
                                  <span>{option.label}</span>
                              </li>
                          ))}
                      </ul>
                  )}
              </div>
          </nav>
        </div>
        <div className="bodyRoutes">
          <Routes>
            <Route path="/" element={<All/>} />
            <Route path="*" element={<NotFoundPage/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AppStructure;